<template>
  <div>
    <service-screen-layout :parts="parts">
      <template v-slot:contracts>
        <SubscriptionContracts 
          :subscription="saleDefinition.definition.softwareSubscription"
          :contracts="saleDefinition.definition.softwareSubscription.subscriptionContracts"
          @addContract="addContract"
          @editLicences="editLicences"
          @removeContract="removeData"
          @removeLicence="removeData"
        />
      </template>
    </service-screen-layout>
    
    <SoftwareContractModal
      ref="contractModal" 
      :modalTitle="'Add Contract'"
      :contract="selectedForContract"
      @saveContract="saveContract"
    /> 
  
    <SoftwareLicenceModal
      ref="licenceModal" 
      :modalTitle="'Licence Bundle'"
      :contract="selectedForLicence"
      :licence="emptyLicence"
      @saveLicenceBundle="saveLicenceBundle"
    /> 
  </div>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
import SubscriptionContracts from "@/components/ObjParts/MultipleCaptured/SubscriptionContracts";
import SoftwareContractModal from '@/components/Admin/Modals/SoftwareContractModal';
import SoftwareLicenceModal from '@/components/Admin/Modals/SoftwareLicenceModal';

export default {
  components: {
    ServiceScreenLayout,
    DateInput,
    SubscriptionContracts,
    SoftwareContractModal,
    SoftwareLicenceModal
  },
  data() {
    return {
      parts: [
        {
          name: "contracts",
          icon: "fa fa-file-text-o",
          displayName: "Subscription Contracts",
          permissions: ["*"],
        }
      ],
      saleDefinition: {
        definition: {
          softwareSubscription: {
            subscriptionContracts: []
          }
        }
      },
      selectedForContract: {},
      selectedForLicence: {},
      emptyLicence: {},
      saleNumber: null
    };
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.getSale(this.saleNumber);
  },
  methods: {
    getSale: function() {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
        (response) => {
          this.saleDefinition = response.data;
          // console.log("saledef"); 
          // console.log(this.saleDefinition); 
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Sale Definition", error);
        }
      );
    },
    saveSale: function() {     
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;
            this.getSale();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Sale Definition", error);
          }
        );
    },
    saveContract: function(contract) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;
      if (contract.softwareSubscriptionNumber) {
        this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, contract).then(
          (response) => {
            this.showSuccess("Contract saved successfully");
            this.getSale();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Contract", error);
          }
        );
      }
    },
    addContract: function() {
      this.selectedForContract = this.saleDefinition.supportingObjs.subscriptionContracts;
      this.selectedForContract.softwareSubscriptionNumber = this.saleDefinition.definition.softwareSubscription.softwareSubscriptionNumber;
      this.selectedForContract.startDate = this.saleDefinition.definition.softwareSubscription.startDate; 
      this.selectedForContract.endDate = this.saleDefinition.definition.softwareSubscription.startDate;
      // console.log("addContract"); 
      // console.log(this.selectedForContract);
      this.$refs.contractModal.open();
    },
    removeData: function(data) {
      this.$swal({
          title: "Are you sure you want to delete this?",
          text: data.name,
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
      }).then((result) => {
          if (result.value) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.saleDefinition.sale.saleNumber;

            this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, data).then(
              (response) => {
                this.showSuccess("Delete Success");
                this.getSale();
              },(error) => {
                console.error(error);
                this.showError("Error with Delete", error);
              });
          }
      });
    },
    editLicences: function(data) {
      this.selectedForLicence = data;
      this.emptyLicence = this.saleDefinition.supportingObjs.licenceBundles;
      this.emptyLicence.subscriptionContractNumber = this.selectedForLicence.subscriptionContractNumber;
      this.emptyLicence.startDate = data.startDate;
      this.emptyLicence.quantity = 1;
      this.emptyLicence.unitCost = 0.00;
      this.$refs.licenceModal.open();
    }, 
    saveLicenceBundle: function(data) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;
      // console.log("saving");
      // console.log(data);
      if (data.subscriptionContractNumber) {
        this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, data).then(
          (response) => {
            this.showSuccess("Licence saved successfully");
            this.getSale();
          },
          (error) => {
            console.error(error);
            this.showError("Error saving Licence", error);
          }
        );
      }
    }
    
  },
};
</script>

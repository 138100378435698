<template>
  <service-screen-layout :parts="parts">
    <template v-slot:summary>
      <div class="row">
        <div class="col-md-8">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Name:</label>
              <input
                type="text"
                readonly
                class="form-control"
                :value="
                  saleDefinition.definition.equipmentrental.equipmentName
                "
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Rental Agreement:</label>
              <input
                type="text"
                readonly
                class="form-control"
                :value="
                  saleDefinition.definition.equipmentrental.rentalTerms
                "
              />
            </div>
            <div class="form-group col-md-6">
              <label>Remaining Months:</label>
              <input
                type="text"
                readonly
                class="form-control"
                :value="remainingInstalmentsComputed"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Monthly Rental (excl. VAT):</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">R</span>
                </div>
                <input
                  type="text"
                  readonly
                  class="form-control"
                  :value="formalMonthlyInstalment"
                />
              </div>
            </div>
            <div class="form-group col-md-6" v-if="saleDefinition.definition.equipmentrental.rentalType != 'Basic Rental'">
              <label>Next Invoice Date:</label>
              <input
                type="text"
                readonly
                class="form-control"
                :value="nextInvoiceDate"
              />
            </div>
          </div>
          <div class="form-row" v-if="saleDefinition.definition.equipmentrental.rentalType != 'Basic Rental'">
            <div class="form-group col-md-6">
              <label>Outstanding Amount (incl. VAT):</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">R</span>
                </div>
                <input
                  type="text"
                  readonly
                  class="form-control"
                  :value="remainingBalance"
                />
              </div>
            </div>
            <div
              class="col-md-6 align-self-center pt-2"
              v-allowed:view="['STAFF']"
            >
              <button
                class="btn btn-info"
                type="button"
                @click="settleAmount()"
                v-if="remainingBalance > 0"
              >
                Settle Amount
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-allowed:view="['STAFF']">
          <div class="card card-default">
            <div class="card-header">
              <h4>Rental Info</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row" v-allowed:view="['STAFF']">
                    <div class="col-md-4">Type: </div>
                    <div class="col-md-8">
                      <strong>
                        {{saleDefinition.definition.equipmentrental.rentalType}}
                      </strong>
                    </div>
                  </div>
                  <div class="row" v-allowed:view="['STAFF']">
                    <div class="col-md-4">Model: </div>
                    <div class="col-md-8">
                      <strong>
                        {{saleDefinition.definition.equipmentrental.equipmentModel}}
                      </strong>
                    </div>
                  </div>
                  <div class="row" v-allowed:view="['STAFF']">
                    <div class="col-md-4">Serial: </div>
                    <div class="col-md-8">
                      <strong>
                        {{saleDefinition.definition.equipmentrental.equipmentSerialNumber}}
                      </strong>
                    </div>
                  </div>
                  <div class="row" v-allowed:view="['STAFF']">
                    <div class="col-md-4">Link: </div>
                    <div class="col-md-8">
                      <strong v-if="saleDefinition.definition.equipmentrental.equipmentLink">
                        {{saleDefinition.definition.equipmentrental.equipmentLink}}
                      </strong>
                      <strong v-else>
                          N/A
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:details>
      <div class="row">
        <div class="col-md-9">
          <div
            class="table-responsive table-bordered"
            v-if="
              saleDefinition.definition.equipmentrental.instalments.length > 0
            "
          >
            <table class="table">
              <thead>
                <tr>
                  <th>Billing Cycle</th>
                  <th>Amount Invoiced (incl. VAT)</th>
                  <th>Total Amount Paid (excl. VAT)</th>
                  <th>Amount Outstanding (incl. VAT)</th>
                  <th>Payments Remaining</th>
                </tr>
              </thead>
              <tbody class="text-nowrap">
                <tr
                  v-for="(instalment, index) in saleDefinition.definition.equipmentrental.instalments"
                  :key="index"
                >
                  <td>
                    {{ prettifyBillingPeriod(instalment.billingPeriod) }}
                  </td>
                  <td>{{ timesVAT(instalment.amount) }}</td>
                  <td>{{ currencyFormat(totalPaid(index)) }}</td>
                  <td>{{ timesVAT(instalment.remainingBalance) }}</td>
                  <td class="text-center">
                    {{ instalment.remainingInstalments }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="alert alert-info">
            No Instalments created.
          </div>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

import SaleFunctions from "@/pages/Services/SaleFunctions";
export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
  },
  computed: {
    remainingInstalmentsComputed() {
      if (this.saleDefinition.definition.equipmentrental.instalments.length > 0) {
        return this.saleDefinition.definition.equipmentrental.instalments[this.saleDefinition.definition.equipmentrental.instalments.length - 1].remainingInstalments;
      } else {
        if (this.saleDefinition.definition.equipmentrental.rentalTerms == "6 Months") {
          return "6";
        }
        if (this.saleDefinition.definition.equipmentrental.rentalTerms == "12 Months") {
          return "12";
        }
        if (this.saleDefinition.definition.equipmentrental.rentalTerms == "24 Months") {
          return "24";
        }
        if (this.saleDefinition.definition.equipmentrental.rentalTerms == "36 Months") {
          return "36";
        }
      }
    },
    formalMonthlyInstalment() {
      return parseFloat(this.saleDefinition.definition.equipmentrental.monthlyInstalmentExVat).toFixed(2);
    },
    remainingBalance() {
      if (this.saleDefinition.definition.equipmentrental.instalments.length > 0) {
        return parseFloat(this.saleDefinition.definition.equipmentrental.instalments[this.saleDefinition.definition.equipmentrental.instalments.length - 1].remainingBalance).toFixed(2);
      } else {
        return "0";
      }
    },
    nextInvoiceDate() {
      if (this.saleDefinition.definition.equipmentrental.instalments.length > 0) {
        return this.prettifyBillingPeriod(this.saleDefinition.definition.equipmentrental.instalments[this.saleDefinition.definition.equipmentrental.instalments.length - 1].billingPeriod + 1)
      } else {
        return "0";
      }
    }
  },
  data() {
    return {
      parts: [
        {
          name: "summary",
          icon: "fa fa-book",
          displayName: "Summary",
          permissions: ["*"],
        },
        {
          name: "details",
          icon: "fa fa-info-circle",
          displayName: "Details",
          permissions: ["*"],
        },
      ],
      documentLines: [],
      saleDefinition: {
        definition: {
          equipmentrental: {
            instalments: [],
            monthlyInstalmentExVat: "0.0",
          },
        },
        product: {},
      },
    };
  },
  mounted() {
    this.loadSale();
  },
  methods: {
    loadSale() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
        },
        (error) => {
          this.showError("Error fetching Sale Definition", error);
          console.error(error);
        }
      );

    },
    totalPaid(index) {
      var total = 0;
      for (let i = 0; i < index + 1; i++) {
        total = total + this.saleDefinition.definition.equipmentrental.instalments[i].amount;
        
      }
      return total;
    },
    settleAmount() {
      this.$swal({
        title: "Settle full remaining amount?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Settle",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              this.$config.aimsAPI + "instalments/settle/equipmentrental",
              this.saleDefinition.definition.equipmentrental.equipmentRentalNumber
            )
            .then(
              (response) => {
                this.loadSale();
                this.showSuccess(response.data.message);
              },
              (error) => {
                this.showError("Error Settling Remaining Amount", error);
                console.error(error);
              }
            );
        }
      });
    },
    // timesVAT(value){
    //   console.log(this.vatRate);
    //   if (value) {
    //     let result = (value * this.vatRate).toLocaleString('en-ZA', {
    //               style: 'currency',
    //               currency: 'ZAR',
    //             });
    //     console.log(result);
    //     return result;
    //   } else {
    //     return 'R ' + 0.00;
    //   }
    // }
  },
};
</script>

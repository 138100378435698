<template>
    <div class="card card-default">
        <div class="card-body">
            <div class="row row-spacing">
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 30%">Description</th>
                                <th style="width: 30%">Type</th>
                                <th style="width: 30%">Value</th>
                                <th class=" th-top-right"></th>
                            </tr>
                        </thead>
                    </table>
                    <SettingTreeTable
                        :fieldNames="columns"
                        :data="tableData"
                        @edit="editSetting"
                    ></SettingTreeTable>
                </div>
            </div>
        </div>
        <modal :title="'Edit Setting: [' + selectedSetting.name + ']'" ref="editSetting" @save="saveSetting()">
            <div class="row">
                <div class="form-group col-md-12">
                    <label class="control-label">
                        Description
                    </label>
                    <input type="text" class="form-control" disabled v-model="selectedSetting.description">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <label class="control-label">
                        Type
                    </label>
                    <input type="text" class="form-control" disabled v-model="selectedSetting.type">
                </div>
                <div class="form-group col-md-9">
                    <label class="control-label">
                        Value
                    </label>
                    <input type="text" class="form-control" v-model="selectedSetting.value">
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';
import SettingTreeTable from '@/components/GeneralComponents/SettingTreeTable';

export default {
    components: {
        Modal,
        SettingTreeTable
    },
    data() {
        return {
            selectedSetting: {
                class: "adept.aims.settings.Setting",
                settingNumber: 0,
                objKey: 0,
                name: "",
                description: "",
                value: "",
                version: 0,
                type: "",
            },

            tableData: [],
            columns: [
                {name: 'Description', id: 'description'},
                {name: 'Type', id: 'type'},
                {name: 'Value', id: 'value'},
            ]
        }
    },
    created() {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    mounted() {
        this.getSystemSettings();
    },
    methods: {
        transformData(data) {
            function mapGroupsToChildren(groups) {
                return groups.map(group => ({
                    name: group.description,
                    children: [
                        ...(group.settings || []).map(setting => ({
                            class: setting.class,
                            settingNumber: setting.settingNumber,
                            objKey: setting.objKey,
                            name: setting.name,
                            displayName: setting.name.split("/")[setting.name.split("/").length-1],
                            description: setting.description,
                            value: setting.value,
                            version: setting.version,
                            type: setting.type,
                        })),
                        ...mapGroupsToChildren(group.groups || [])
                    ]
                }));
            }

            return mapGroupsToChildren(data.groups);
        },
        getSystemSettings() {
            this.$http.get(this.$config.aimsAPIv2 + "settings").then(response => {
                    this.tableData = this.transformData(response.data);
                },
                error => {
                    this.showError("Error fetching System Settings", error);
                    console.error(error);
                });
        },
        editSetting(item) {
            this.selectedSetting = item;
            this.$refs.editSetting.open();
        },
        saveSetting() {
            this.$refs.editSetting.isLoading();
            this.$http.post(this.$config.aimsAPI + "system/setting/save", this.selectedSetting).then(response => {
                    this.showSuccess(response.data.message);
                    this.getSystemSettings();
                },
                error => {
                    this.showError("Error saving System Setting", error);
                    console.error(error);
                }).finally(() => {
                    this.$refs.editSetting.close();
                });
        },
    },
}
</script>

<style>
.th-top-left {
  border-top-left-radius: 8px;
}

.th-top-right {
  border-top-right-radius: 8px;
}
</style>
<template>
  <div>
    <div class="service-title-container">
      <div class="service-title-wrapper">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6 pl-0">
              <div class="card border mb-0">
                <div class="card-header">
                      <h5>{{ saleDescription }}</h5>
                </div>
                <div class="card-body">
                <div class="mb-2" v-show="status === 'Active'">
                  <div v-if="!readonly" v-allowed:view="['STAFF', 'CLIENT']" class="input-group">
                      <input
                        v-model="saleLabelInput"
                        type="text"
                        class="form-control"
                        placeholder="Service Label"
                        :disabled="!userMayEdit"
                      />
                      <div class="input-group-append" v-allowed:view="['ADMIN', 'MANAGER', 'SALES_META', 'SALES_META_STAFF']">
                        <button
                          class="btn btn-success"
                          type="button"
                          @click="saleLabelChanged()"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </div>
                    </div>
                </div>
                <hr>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-10 d-flex pl-0 flex-wrap">
                        <div class="sale-tag m-1" v-for="tag in saleTags" :key="tag.saleTagValueNumber">
                          <div class="badge text-white" v-tooltip="`Edit Sale Tag`" style="cursor: pointer" :style="`background: ${tag.saletag.colour}`" @click="editSaleTag(tag)">
                              <i class="fa fa-tag" aria-hidden="true"></i>
                              <span class="ml-1">{{ tag.value }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 d-flex justify-content-end  align-items-center pr-0" v-allowed:view="['ADMIN', 'CLIENT_ADMIN', 'MANAGER', 'SALES_META', 'SALES_META_STAFF']">
                          <button class="btn btn-sm btn-info" :class="{'btn-danger' : saleTags.length === 5}" @click="addSaleTag" :disabled="saleTags.length === 5">Add Tag</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-0">
                <div class="card border">
                  <div class="card-header">
                    <h5>{{ product.description }}</h5>
                  </div>
                  <div class="card-body">
                    <div class="row align-items-center rounded">
                    <div class="col">
                      <div class="product-badges d-flex justify-content-evenly">
                        <span class="badge bg-gray mx-1">{{ product.vendor.name }}</span>
                        <span class="badge bg-gray mx-1">{{ product.classificationType }}</span>
                        <span class="badge bg-gray mx-1">{{ product.segment.name }}</span>
                        <span class="badge bg-gray mx-1">{{ product.subsegment.name }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="d-flex justify-content-end align-items-center">
                          <ProviderLogo
                            v-if="product.supplier.name != product.vendor.name"
                            :providerName="product.supplier.name"
                            class="mx-2"
                          />
                          <ProviderLogo
                            :providerName="product.vendor.name"
                            class="mx-2"
                          />
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <AddSaleTagModal 
      ref="openAddSaleTagModal"
      :modalTitle="modalTitle"
      :saleTag="saleTag"
      :saleTagValue="saleTagValue"
      :saleTags="saleTags"
      :allClientSaleTags="filteredSaleTags"
      @reloadTags="getSaleTags"
      @reloadSaleTags="getAllSaleTags"
    />
    <SaleTagModal 
      ref="openSaleTagModal"
      :modalTitle="modalTitle"
      :selectedTag="selectedTag"
      :saleTag="saleTag"
      :allClientSaleTags="filteredSaleTags"
      @reloadTags="getSaleTags"
      @reloadSaleTags="getAllSaleTags"
    />
  </div>
</template>

<script>
import ProviderLogo from "@/components/GeneralComponents/ProviderLogo";
import AddSaleTagModal from '@/components/Admin/Modals/AddSaleTagModal';
import SaleTagModal from '@/components/Admin/Modals/ModifySaleTagModal';

export default {
  components: {
    ProviderLogo,
    AddSaleTagModal,
    SaleTagModal
  },
  props: {
    saleDescription: {
      type: String,
      required: true,
    },
    saleLabel: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      clientNumber: null,
      saleLabelInput: "",
      saleNumber: null,
      saleTags: [],
      modalTitle: '',
      saleTag: {},
      saleTagValue: {},
      allClientSaleTags: [],
      filteredSaleTags: [],
      tagsInUse: [],
      selectedTag: {},
    };
  },
  watch: {
    saleLabel: {
      immediate: true,
      handler(val, oldVal) {
        this.saleLabelInput = val;
      },
    },
  },
  mounted() {
    this.saleNumber = parseInt(this.$route.params.saleNumber);
    this.clientNumber = parseInt(this.$route.params.clientNumber);
    
    this.getSaleTags();
  },
  computed: {
    userMayEdit() {
      return this.userContainsPermission(["ADMIN", "CLIENT_ADMIN", "MANAGER", "SALES_META", "SALES_META_STAFF"]);
    }
  },
  methods: {
    getSaleTags: function() {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.get(`${hostUrl}saletags/sale/${saleNumber}`).then(
        (response) => {
          this.saleTags = response.data;          
          
          this.getAllSaleTags();
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Tag data", error);
        }
      )
    },
    addSaleTag: function() {
      this.$refs.openAddSaleTagModal.open();   
      this.modalTitle = 'Create Tag';
      this.saleTag = {
        class: "za.co.adept.aims.segment.SaleTag",
        clientNumber: this.clientNumber,
        colour: "#e2231a"
      };
      this.saleTagValue = {
        class: "za.co.adept.aims.segment.SaleTagValue",
        saleNumber: this.saleNumber,
      };

      this.getAllSaleTags(); 
    },
    editSaleTag: function(tag) {
      this.$refs.openSaleTagModal.open();
      this.modalTitle = `Edit Tag: ${tag.value}`;
      this.selectedTag = this.deepCopyObject(tag);
      this.saleTag = this.selectedTag.saletag;      
    },
    getAllSaleTags: function() {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;    

      this.$http.get(`${hostUrl}saletags/client/${clientNumber}`).then(
          (response) => {
            this.allClientSaleTags = response.data;  
            
            const allClientSaleTags = this.allClientSaleTags;
            const saleTags = this.saleTags;

            this.filteredTags(allClientSaleTags, saleTags);            
            
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Tags", error);
          }
      );
    },
    filteredTags: function(allClientSaleTags, saleTags) {
        this.filteredSaleTags = allClientSaleTags.filter(clientSaleTag => {
          return !saleTags.find(saleTag => {                    
            return saleTag.saleTagNumber === clientSaleTag.saleTagNumber;
          });
        });
        
        return this.filteredSaleTags;
    },
    linkTo() {
      this.openNewWindow("SALE", this.$route.params.saleNumber);
    },
    saleLabelChanged() {
      this.$emit("saleLabelChanged", this.saleLabelInput);
    },
    reloadSale() {
      this.$emit("reloadSale");
    },
  },
};
</script>
<style lang="scss" scoped>
  .card {

    .card-header {
      background: #3A3F51;

      h5 {
          color: #F5F7FA;
          padding: .2em 0;
          margin: 0;
          font-weight: 700;
      }
    }

    .card-body {
      height: auto;
    }
  }
</style>


